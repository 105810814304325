<template>
  <div>
    <Pane :bottom="0"
          :showRemarks="false">
      <a-tabs v-model="activeKey"
              :tabBarStyle="{
          margin: '0',
        }">
        <a-tab-pane v-for="item in tabList"
                    :key="item.key"
                    :tab="item.name"
                    :disabled="item.disabled"></a-tab-pane>
      </a-tabs>
    </Pane>

    <div class="container">
      <SummaryReport ref="claimRef"
                     v-show="activeKey === '0' && pData.projectName"
                     :msg="pData"></SummaryReport>
      <SummaryReportReview ref="couterClaimRef"
                           v-show="activeKey === '1' && pData.projectName"
                           :msg="pData"></SummaryReportReview>
    </div>
  </div>
</template>

<script>
import SummaryReport from "./components/summary-report";
import SummaryReportReview from "./components/summary-report-review";

import { fetchDetail } from "@/api/epc";

export default {
  name: "completeReport",
  components: {
    SummaryReport,
    SummaryReportReview,
  },
  data() {
    return {
      activeKey: "0",
      tabList: [
        { key: "0", name: "总结报告", disabled: false },
        { key: "1", name: "总结报告评审表", disabled: false },
      ],

      form: this.$form.createForm(this),
      pid: "", // 项目id
      pData: {},

      isView: false,
    };
  },
  mounted() {
    const { query } = this.$route;
    const { activeKey, pid, id, isView } = query || {}; // 通过activeKey判断当前是哪个tab，id判断是否是编辑页面，isView判断是否是查看页面，

    if (!pid) {
      return;
    }
    this.activeKey = activeKey || "0";
    this.pid = pid;
    this.isView = Boolean(isView) || false;

    if (id) {
      this.setTab();
    }
    this.getProjectInfo();
  },
  methods: {
    setTab() {
      // 从编辑进入页面，设置非活跃状态的tab不可用
      this.tabList.forEach((item) => {
        if (item.key !== this.activeKey) {
          item.disabled = true;
        }
      });
    },
    getProjectInfo() {
      fetchDetail(this.pid).then((res) => {
        this.pData = {
          pid: this.pid,
          designCode: res.designCode,
          projectName: res.name,
        };
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}
</style>
