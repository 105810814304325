<template>
  <div>
    <a-form :form="form"
            @submit="handleSubmit"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 12 }"
            :colon="false">
      <a-row>
        <a-col :lg="12"
               :md="12"
               :sm="24">
          <a-form-item label="当前项目"
                       class="default-highlight">
            <a-input :disabled="true"
                     :placeholder="msg.designCode"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="24"
               :md="24"
               :sm="24">
          <a-form-item label="工程名称"
                       class="default-highlight"
                       :label-col="{ span: 4 }"
                       :wrapper-col="{ span: 18 }">
            <a-input :disabled="true"
                     :placeholder="msg.projectName" />
          </a-form-item>
        </a-col>

        <a-col :lg="24"
               :md="24"
               :sm="24">
          <a-form-item :label-col="{ span: 4 }"
                       :wrapper-col="{ span: 18 }">
            <span slot="label"
                  class="">附件</span>
            <div class="link-list"
                 v-decorator="[
                'file',
                { rules:[{ required: true, message: '请上传附件' }]}
                ]">
              <a-checkbox-group :value="selectedFileList"
                                @change="onSelectFile">
                <div class="link"
                     v-for="item in fileList"
                     :key="item.id">
                  <a-checkbox :value="item.id">
                    <a target="_blank"
                       download
                       :href="item.completePath">{{
                        item.name
                      }}</a>
                  </a-checkbox>
                </div>
              </a-checkbox-group>

              <div class="control-bar">
                <FileUpload @uploaded="uploaded">
                  <div class="control">
                    <a-icon type="upload" />
                  </div>
                </FileUpload>

                <div class="control"
                     @click="deleteFile">
                  <a-icon type="delete" />
                </div>
              </div>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
          <a-button htmlType="submit"
                    type="primary">提交</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>
<script>
import FileUpload from "@/components/file-upload";

import { add, edit, fetchDetail } from "@/api/epc/complete";

export default {
  props: {
    msg: {
      type: Object,
      default: () => ({
        pid: "",
        designCode: "",
        projectName: "",
      }),
    },
  },
  components: {
    FileUpload,
  },
  data() {
    return {
      stage: "epc_closing_final_report",
      isView: false,

      form: this.$form.createForm(this),
      id: "",

      fileList: [],
      selectedFileList: [],
    };
  },
  mounted() {
    const { query } = this.$route;
    const { id, hid, isView } = query || {};

    this.id = id;
    this.hid = hid;
    this.isView = Boolean(isView) || false;

    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      fetchDetail({ id: this.id }).then((res) => {
        if (!res.id) return;

        if (res.attachmentList) {
          this.form.setFieldsValue({
            file: res.attachmentList,
          });

          this.fileList = res.attachmentList;
        }
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          delete values.file;
          if (!this.id) {
            add({
              pid: this.msg.pid,
              designCode: this.msg.designCode,
              projectName: this.msg.projectName,
              attachments: this.fileList,
              type: this.stage,
            }).then(() => {
              this.$close(this.$route.path);
            });
          } else {
            edit({
              id: this.id,
              historyId: this.hid,
              pid: this.msg.pid,
              designCode: this.msg.designCode,
              projectName: this.msg.projectName,
              attachments: this.fileList,
              type: this.stage,
            }).then(() => {
              this.$close(this.$route.path);
            });
          }
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];

      if (this.fileList.length > 0) {
        this.form.setFieldsValue({
          file: true,
        });
      }
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];

      if (this.fileList.length === 0) {
        this.form.setFieldsValue({
          file: "",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.center {
  margin-top: 80px;
}
</style>
